<template>
	<div class="ad-main">
		<div class="main">
			<el-card>
				<div style="margin:0 auto; margin-bottom:30px">
					<h2>申请单预览</h2>
				</div>
				<div class="marB10 border flex h80">
					<div class="leftTitle">
						出差人信息
					</div>
					<div class="right-main flex">
						<div class="topItem">
							<span class="title-width">成本中心：</span>
							<span>{{dataDetail.costCenterName}}</span>
						</div>
						<div class="topItem">
							<span class="title-width">出差人：</span>
							<span>{{cxr}}</span>
						</div>
						<div class="topItem">
							<span class="title-width">随行人：</span>
							<span>{{sxrName || ''}}</span>
						</div>
						<div class="topItem">
							<span class="title-width">目的地：</span>
							<span>{{dataDetail.mdd}}</span>
						</div>
						<div class="topItem">
							<span class="title-width">出差日期：</span>
							<span>
								<span>{{dataDetail.dateBegin}}</span>
								<span>至</span>
								<span>{{dataDetail.dateEnd}}</span>
							</span>
						</div>
                        <div class="topItem">
							<span class="title-width">出差天数</span>
							<span>{{routeDiffTime(dataDetail)}}</span>
						</div>
						<div class="topItem">
							<span class="title-width">出差事由：</span>
							<span>{{dataDetail.ccsy}}</span>
						</div>
					</div>
				</div>
				<div class="marB10 border flex h80">
					<div class="leftTitle">
						交通计划
					</div>
					<div class="xc-main">
						<el-table :data="jhList" style="width:996px" border :header-cell-style="header">
                            <!-- 序号	出发日期	到达日期	出差天数	出行方式	出行人	出发地	到达地	航班号/车次	舱位/席别	国内交通费	 	打车费	出差类型	出差事由	住宿说明	订单号	原订单操作	新订单操作	打卡记录 -->
						    <el-table-column  type="index" width="50" label="序号"></el-table-column>
						    <el-table-column prop="proType" label="行程类型" width="80">
								<template slot-scope="scope">{{  proTypeConfig(scope.row.proType)}}</template>
						    </el-table-column>
                            <el-table-column prop="against" label="是否违背" width="80">
								<template slot-scope="scope">
                                    <span v-if="scope.row.against==0">未违背</span>

                                    <i class="iconfont yj-icon-weizhang" v-else style="font-size:20px;color:red" ></i>
                                </template>
						    </el-table-column>
						    <el-table-column prop="departDate" label="出发日期" width="90"></el-table-column>
                            <el-table-column prop="arriveDate" label="到达日期" width="90"></el-table-column>
							<el-table-column prop="departCityName" label="出发城市" width="80"></el-table-column>
							<el-table-column prop="arriveCityName" label="到达城市" width="80"></el-table-column>
							<el-table-column prop="cxr" label="出行人" width="100"></el-table-column>
							<el-table-column prop="flightNo" label="航班号/车次" width="100"></el-table-column>
							<el-table-column prop="cabin" label="舱位/席别" width="80"></el-table-column>
							<el-table-column prop="dcf" label="打车费" width="80"></el-table-column>
							<el-table-column prop="zzsm" label="住宿说明" width="80">
								<template slot-scope="scope">{{  zzsmType(scope.row.zssm) || ''}}</template>
							</el-table-column>
							<el-table-column prop="cclxMc" label="出差类型" width="80">
							</el-table-column>
							<el-table-column prop="fyys" label="费用预算" width="86"></el-table-column>
							<el-table-column prop="by1" label="出差事由" width="100"></el-table-column>
						  </el-table>
					</div>	
				</div>
				<div class="marB10 border flex h80">
					<div class="leftTitle">
						住宿安排
					</div>
					<div class="xc-main">
                        <!-- 序号	入住日期	离店日期	入住晚数	入住城市	酒店名称	房型	房间数量	住宿费	 	出差餐费	订单号	原订单操作	新订单操作	打卡记录 -->
						<el-table :data="hotelList" style="width:996px" border :header-cell-style="header">
						    <el-table-column  type="index" width="50" label="序号"></el-table-column>
						    <el-table-column prop="departCityName" label="入住城市" width="80"></el-table-column>
                            <el-table-column prop="against" label="是否违背" width="80">
								<template slot-scope="scope">
                                    <span v-if="scope.row.against==0">未违背</span>

                                    <i class="iconfont yj-icon-weizhang" v-else style="font-size:20px;color:red" ></i>
                                </template>
                            </el-table-column>
						    <el-table-column prop="departDate" label="入住日期" width="100"></el-table-column>
                            <el-table-column prop="arriveDate" label="离店日期" width="100"></el-table-column>
                            <el-table-column  label="入住晚数" width="100">
                                    <template slot-scope="scope">
                                        <span>{{routeDiffTime2(scope.row)}}</span>
                                 </template>
                            </el-table-column>
							<el-table-column prop="cxr" label="入住人" width="120"></el-table-column>
							<el-table-column prop="flightNo" label="酒店名称" width="150"></el-table-column>
							<el-table-column prop="cabin" label="房型" width="100"></el-table-column>
							<el-table-column prop="fjs" label="房间数" width="75"></el-table-column>
							<el-table-column prop="fyys" label="住宿费" width="80"></el-table-column>
                            <el-table-column prop="cf" label="出差餐费" width="80"></el-table-column>
                            <el-table-column  label="订单号" width="100"  >
                                 <template slot-scope="scope">
                                        <div v-for="(item, index) in scope.row.routeUnionOrderList" :key="index">
                                             <p style="color:#409EFF" @click="jumpToOrderNo(scope.row,item)">{{item.orderNo}}</p>
                                        </div>
                                 </template>
                                 </el-table-column>
                                 <el-table-column prop="ccsy" label="出差事由" width="100"></el-table-column>
    
						</el-table>
					</div>	
				</div>

				<div class="marB10 border flex h80">
					<div class="leftTitle">
						备注
					</div>
					<div class="xc-main ">
						<div class="xc-text">{{dataDetail.remark || dataDetail.by1}}</div>
					</div>	
				</div>
				<div class="marB10 border flex h80">
					<div class="leftTitle">
						费用合计
					</div>
					<div class="ys-main">
						<div class="xc-ys">
							<span class="bfb-w25">交通预算: ￥{{dataDetail.jtys}}</span>
							<span class="bfb-w25">住宿预算: ￥{{dataDetail.zsys}}</span>
                            <span class="bfb-w25">餐饮预算: ￥{{dataDetail.cyys}}</span>
							<span class="bfb-w25">其他预算: ￥{{dataDetail.qtys}}</span>
						</div>
						<div class="xc-ys">
							预计本次出差总预算：￥{{dataDetail.zys}}
						</div>
					</div>	
				</div>
            	<div class="marB10 border flex h80">
					<div class="leftTitle">
						审批规则
					</div>
					<div class="xc-main ">
						<div class="xc-text">{{spgz}}</div>
					</div>	
				</div>
				<div style="margin:20px 0;" v-if="query.workItemId && dataDetail.status==1 && ifApprover==1 && !outTime">
					<el-button class="btn" type="primary" @click="approvalSend">提交</el-button>
				</div>
                 <el-button class="btn"  @click="closeHandler">关闭</el-button>
                    <el-button  class="setBtn1" plain @click="saveAndApproval(1)">保存草稿</el-button>
					<el-button type="primary" class="setBtn2" @click="saveAndApproval(2)">保存并送审</el-button>
			</el-card>
		</div>

	</div>
</template>

<script>
    import moduleList from './ccsqdModule/moduleList.js'
    import {mapState,mapMutations} from 'vuex';
	export default{
		name:'CcsqdDetail',
        components: {

		},

		data(){
			return {
				header:{
					background:'#FAFAFA',
					height:40+'px',
				},
                minEmpCxr:{ //最低职级

                },
                sxrName:'',
                spgz:'',
                cxr:'',
                jhList :[],
				hotelList : [],
                dataDetail:'',
                moduleList,
			}
		},

		computed:{
			// 接收传值
			query(){
				return this.$route.query || {};
			},
			// 

			empInfo(){
				let userInfo = this.$common.getUserInfo()
				return userInfo.empInfo;
			},
            isShowChange(){ //是否展示变更？
                let show=false 
                let myDate = new Date(); 
                let thisDate = myDate.getFullYear()+"-"+(myDate.getMonth()+1)+"-"+myDate.getDate();
                if( (this.$common.getDiffDate(thisDate, this.dataDetail.dateEnd)>=0) ){
                    show=true
                }else{
                    show=false
                }
                return show
            },
			//当前登录人是否是审批人
			ifApprover(){
				return this.empInfo.ifApprover || 0;
			},
            routeDiffTime(){
                return function(item){
                    const m1 = this.$moment(item.dateBegin);
                    const m2 = this.$moment(item.dateEnd);
                    const day = m2.diff(m1, 'd');
                    return day
                }
            },
            routeDiffTime2(){
                return function(item){
                    const m1 = this.$moment(item.departDate);
                    const m2 = this.$moment(item.arriveDate);
                    const day = m2.diff(m1, 'd');
                    return day
                }
            },

            proTypeConfig(){
                    return function(type){
                    let routeConfig = {
                            "10904": '火车',
                            '10901': '飞机',
                            '10903': '酒店',
                            '109991': '大巴',
                            "109992": '轮船',
                            "109993": '自驾',
                            "10999": '其他'
                        }
                    return routeConfig[type]
                } 
            },

            changeZtConfig(){
                return function(item){
                    let changeZt ={
                        // 变更单状态0草稿；1审批中；2审批通过；3审批拒绝；4已删除 ,
                        "0":"草稿",
                        "1":"审批中",
                        "2":"审批通过",
                        "3":"审批拒绝",
                        "4":"已删除",

                    }
                    return changeZt[item]
                }
            },


     
            zzsmType(){
                return function(type){
                    return this.moduleList.roomsTypeList.filter(item=>item.value==type)[0].name
                }
            },
            cclxType(){
                return function(type){
                    return this.moduleList.goTypeList.filter(item=>item.value==type)[0].name
                }
            }
		},
		mounted(){
			this.outTime = (this.query.appType == 3)
			this.getCcsqdInfo();
		},
		methods:{
            getCcsqdInfo(){
               let info=  this.$common.sessionget('previewParams')
               this.sxrName=''
               let sxrList=[]
               this.dataDetail=info.querypParams
               sxrList=info.querypParams.fastPersonList || []
               sxrList.forEach(item=>{
                   if(item.sxr!=0){
                       this.sxrName=this.sxrName+item.cxrName+','
                   }else{
                       this.cxr=item.cxrName
                   }
               })
               this.spgz=info.spgz
            		this.jhList = [];
					this.hotelList = [];
                    this.dataDetail.fastRouteList.forEach(element => {
                        if(element.proType!=10903){
                            this.jhList.push(element)
                            console.log( this.jhList," this.jhList")
                        }else{
                            this.hotelList.push(element)
                        }
                    });
            },
                    saveAndApproval(num){
                        let params=this.$common.sessionget('previewParams').querypParams
                        params.addMethod=num
                         this.$conn.getConn('xakCcsqd.xakVipCcsqd')(params,res=>{
                             	let data = res.data|| {};
                                let errMsg = "";
                                if(data.status==1){
                                    errMsg = '送审失败'
                                }else if(data.status==0){
                                    errMsg = '草稿保存成功'
                                }else if(data.status==2 && num==1){
                                    errMsg = '草稿保存成功'
                                }else if(data.status==2 && num==2){
                                    errMsg = '送审成功'
                                }
                                if(data.status==1){
                                    this.$message({
                                        type: 'error',
                                        message: errMsg
                                    })
                                    return
                                }else{
                                    this.$message({
                                        type: 'success',
                                        message: errMsg
                                    })
                                    this.$router.push({
                                        path:'/yjTravel/myTravel/userCenter/travelFormAdmin'
                                    })
                                    
                                }
                         } ,(err)=>{
                        if(err.errCode == 9883){
                                this.$confirm(err.tips , '温馨提示', {
                                        confirmButtonText: '继续保存',
                                        cancelButtonText: '取消',
                                        type: 'warning'
                                        }).then(() => {
                                            params.repeatFlag=1
                                            let obj={
                                                querypParams:params,
                                                spgz:this.spgz
                                            }
                                            this.$common.sessionset('previewParams',obj)
                                            this.saveAndApproval(num);
                                        }).catch(() => {
                                                
                                        });
                                
                            }else{
                                this.$message({
                                    type: 'error',
                                    message: err.tips
                                })
                            }
                         })
                        
                    },
         

                closeHandler(){
      				this.$confirm('此操作将关闭本页面, 是否继续?', '提示', {
							  confirmButtonText: '确定',
							  cancelButtonText: '取消',
							  type: 'warning'
				        }).then(() => {
							window.close();
				        }).catch(() => {
				            this.$message({
				            type: 'info',
				            message: '已关闭'
				        });          
				});
            },

		}
	}
</script>

<style scoped lang="scss">
	 
	.ad-main{
		background-color: #EEF1F6;
		padding:40px 0;
		.main{
			margin: 0 auto;
			width: 1200px;
			.flex{
				display: flex;	
			}
            .color007 {
                color: #007aff;
                }
			.between{
				justify-content: space-between;
			}
			.marB10{
				margin-bottom: 10px;
			}
			.border{
				border: 1px solid #E6E6E6;
			}
			.h80{
				min-height: 80px;
			}
            .ml20{
                margin-left: 20px;
            }
			.btn{
				width: 80px;
				height: 36px;
				font-size: 14px;
			}
			.leftTitle{
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #FAFAFA;
				border-right: 1px solid #E6E6E6;
				width: 120px;
			}
			.padTB30{
				padding:30px 0;
			}
			.sp-main{
				width: 1036px;
				padding: 20px 0 20px 40px;
				.ss-item{
					text-align: left;
					line-height: 30px;
					width: 100px;
				}
				.ss-right{
					width: 700px;
				}
				.sp-item{
					font-size: 14px;	
				}
			}
			.ys-main{
				width: 1036px;
				text-align: left;
				padding: 15px 0;
				.xc-ys{
					padding-left: 40px;
					line-height: 40px;
					.bfb-w25{
						width: 25%;
						display: inline-block;
					}
				}
			}
			.xc-main{
				width: 1036px;
				text-align: left;
				padding:20px 40px;
				
				.xc-text{
					padding: 24px 0 ;
					line-height: 30px;
				}
				
				.bfb-w5{
					width: 5%;
				}
				.bfb-w8{
					width: 8%;
				}
				.bfb-w10{
					width: 10%;
				}
				.bfb-w20{
					width: 20%;
				}
			}
			.right-main{
				width: 1036px;
				flex-wrap: wrap;
				padding: 10px 0;
				.topItem{
					width: 33%;
					text-align: left;
					padding-left: 40px;
					line-height: 30px;
				}
				.title-width{
					width: 80px;
					display: inline-block;
				}
			}
		}
	}
</style>

let obj={
    goTypeList: [
        {name: "请选择", value: "0"},
        {name: "事业部负责人会议",value:"7"},
        {name: "大区内全体会议",value:"2"}, 
        {name:'大区内部Team长会议',value:"8"},
        {name:'Team会议',value:"9"},
        {name: "参加展会",value:"3"},
        {name: "其他行政事务",value:"5"},
        {name:'总部召开全体会议', value:'10'},
        {name: "日常出差/终端拜访/协访",value:"4"}, 
    ],
    roomsTypeList: [
        {name: "请选择", value: "0"},
        {name: "公司支付", value: "1"},
        {name: "他人支付",value: "2"},
        {name: "住朋友家",value: "3"}  
    ],
   filtergoType:{
        "2":'大区内全体会议',
        "3":'参加展会',
        '4':'日常出差/终端拜访/协访',
        '5':'其他行政事务',
        '7':'事业部负责人会议',
        '8':'大区内部Team长会议',
        '9':'Team会议',
        '10':'总部召开全体会议'
   },
   fliterRoomsType:{
        '1':"公司支付",
        '2':'他人支付',
        "3":'住朋友家'
   },
   routeConfig:{
        "10904": '火车',
        '10901': '飞机',
        '10903': '酒店',
        '109991': '大巴',
        "109992": '轮船',
        "109993": '自驾',
        "10999": '其他'
    }
}
export default obj